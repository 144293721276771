var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-manage-floating-ads","title":"Manage floating ads","no-close-on-backdrop":""},on:{"ok":_vm.onOk}},[_c('ValidationObserver',{ref:"cmsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"image_src","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image"}},[_c('CmsFileSelect',{attrs:{"value":_vm.cmsFloatingAdsForm.imageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.cmsFloatingAdsForm.imageSrc = value || ''); }}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"display_condition","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Floating position"}},[_c('b-select',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.cmsFloatingAdsForm.floatingPosition),callback:function ($$v) {_vm.$set(_vm.cmsFloatingAdsForm, "floatingPosition", $$v)},expression:"cmsFloatingAdsForm.floatingPosition"}},_vm._l((_vm.positions),function(item,index){return _c('b-select-option',{key:index,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"display_condition","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"External link?"}},[_c('b-form-checkbox',{attrs:{"switch":"","state":errors[0] ? false : null,"size":"lg"},model:{value:(_vm.cmsFloatingAdsForm.isExternalLink),callback:function ($$v) {_vm.$set(_vm.cmsFloatingAdsForm, "isExternalLink", $$v)},expression:"cmsFloatingAdsForm.isExternalLink"}})],1)]}}],null,true)}),(_vm.cmsFloatingAdsForm.isExternalLink)?_c('b-form-group',{attrs:{"label":"External link target"}},[_c('b-form-input',{attrs:{"placeholder":"https://google.com"},model:{value:(_vm.cmsFloatingAdsForm.externalLinkTo),callback:function ($$v) {_vm.$set(_vm.cmsFloatingAdsForm, "externalLinkTo", $$v)},expression:"cmsFloatingAdsForm.externalLinkTo"}})],1):_c('b-form-group',{attrs:{"label":"Internal link target"}},[_c('b-form-input',{attrs:{"placeholder":"/dashboard"},model:{value:(_vm.cmsFloatingAdsForm.internalLinkTo),callback:function ($$v) {_vm.$set(_vm.cmsFloatingAdsForm, "internalLinkTo", $$v)},expression:"cmsFloatingAdsForm.internalLinkTo"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }